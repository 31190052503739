#UserAgent
_ua = do ->
  IE = document.uniqueID
  ltIE6 = typeof window.addEventListener is undefined and typeof document.documentElement.style.maxHeight is undefined
  ltIE7 = typeof window.addEventListener is undefined and typeof document.querySelectorAll is undefined
  ltIE8 = typeof window.addEventListener is undefined and typeof document.getElementsByClassName is undefined
  ltIE9 = IE and typeof window.Worker is undefined
  IE6 = IE and ltIE6
  IE7 = IE and ltIE7 and !ltIE6
  IE8 = IE and ltIE8 and !ltIE7 and !ltIE6
  IE9 = IE and ltIE9 and !ltIE8 and !ltIE7 and !ltIE6
  IE10 = IE and !ltIE9 and !ltIE8 and !ltIE7 and !ltIE6
  Webkit = !document.uniqueID and !window.opera and !window.sidebar and !window.orientation and window.localStorage
  Safari = Webkit and navigator.vendor.search(/apple/i) isnt -1
  Chrome = Webkit and navigator.vendor.search(/google/i) isnt -1

  return {
    IE:IE,
    ltIE6:ltIE6,
    ltIE7:ltIE7,
    ltIE8:ltIE8,
    ltIE9:ltIE9,
    IE6:IE6,
    IE7:IE7,
    IE8:IE8,
    IE9:IE9,
    IE10:IE10,
    Firefox:window.sidebar,
    Opera:window.opera,
    Webkit:Webkit,
    Safari:Safari,
    Chrome:Chrome,
    Mobile:window.orientation
  }

#URL
url = do ->
  href = location.href.split '/'

  localRegex = /^\d+\.\d+\.\d+\.\d+/
  workRegex = /^.*\/pc\/[^\/]+\/.*$/

  for val , i in href
    if val == '' or i == href.length - 1 and val.indexOf '.'
      href.splice(i,1)

  if localRegex.test(location.hostname) is true or location.hostname.indexOf('localhost') isnt -1
    length = 2

  else if workRegex.test(location.href) is true
    length = 3

    for val , i in href
      if val is 'pc' and href[i-1] is 'work'
        length = 4

  else
    length = 1

  path = ''

  for j in [0..(length)]
    path += href[j]

    if j == 0
      path += '//'

    else
      path += '/'

  return path

#スムーススクロール関数モーション定義
jQuery.extend(
  jQuery.easing,
    easeInOutCirc:
      (x, t, b, c, d) ->
        if (t/=d/2) < 1
          return -c/2 * (Math.sqrt(1 - t*t) - 1) + b
        c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b
)

window.onpageshow = (evt) ->
  if evt.persisted
    location.reload()
  return

event =
  all: 'mousedown touchstart pointerdown mouseenter mouseleave'
  type: ''
  start: ''
  enter: ''
  leave: ''
  move: ''
  end: ''
# イベント判定

event.check = (e) ->
  _default = e.type
  event.start = _default
  event.move = if _default == 'pointerdown' then 'pointermove' else if _default == 'touchstart' then 'touchmove' else 'mousemove'
  event.end = if _default == 'pointerdown' then 'pointerup' else if _default == 'touchstart' then 'touchend' else 'click'
  event.enter = if _default == 'pointerenter' then _default else if _default == 'touchenter' then _default else 'mouseenter'
  event.leave = if _default == 'pointerleave' then _default else if _default == 'touchleave' then _default else 'mouseleave'
  return

event.on = (type, elm, fn, delegate) ->
  $(document).off(event.all, elm).on event.all, elm, (e) ->
    e.preventDefault()
    event.check e
    if fn != null
      if e.type == event.enter or e.type == event.leave
        # mouseenter mouseleaveのとき、コールバック関数を即実行
        fn this, e
      else
        if type == 'bind'
          # jQuery $(element).on(event, func);
          $(elm).off(event.end).on event.end, (e) ->
            fn this, e
            return
        else if type == 'live'
          # jQuery $(document).on(event, element, func);
          $(document).off(event.end, elm).on event.end, elm, (e) ->
            fn this, e
            return
        else if type == 'delegate'
          # jQuery $(parentsElement).on(event, element, func);
          $(delegate).off(event.end, elm).on event.end, elm, (e) ->
            fn this, e
            return
    else
      # 引数にコールバック関数が定義されていないときの処理
    return
  return

$ ->
  $id = $('body').attr('id')
  $class = $('body').attr('class')

  if $class.indexOf('mail') != 0
    firstDownFlg = true
    firstUpFlg = false
    $('#header').headroom
      tolerance:
        up:0
        down:5
      offset:0
      classes:
        initial: 'animated'
        pinned: 'slideDown'
        unpinned: 'slideUp'
      onPin: ->
        firstUpFlg = true
        if !firstDownFlg
          return $('#header').removeClass('slideUpFirst')
        return
      onUnpin: ->
      onTop: ->
        firstDownFlg = true
        $('#header').removeClass 'slideDownFirst slideDown pin outreturn up'
      onNotTop: ->
        if firstDownFlg
          $('#header').addClass 'slideUpFirst'
          return firstDownFlg = false
    $(window).scroll ->
      if firstUpFlg and $(this).scrollTop() <= $('#header').height()
      else if  firstUpFlg
        $('#header').addClass 'outreturn'
        return firstUpFlg = false
      if $(this).scrollTop() <= $('#header').height()+40
        $('#header').addClass 'pin'
      else $('#header').removeClass 'pin'
      if $(this).scrollTop() >= $('#header').height()
        $('#header.slideDown').addClass 'up'


  topBtn = $("#pagetop")
  #最初はボタンを隠す
  topBtn.hide()
  #スクロールが300に達したらボタンを表示させる
  $(window).scroll ->
    if $(this).scrollTop() > 300
      topBtn.fadeIn()
    else
      topBtn.fadeOut()
    return
  topBtn.click ->
    $("body,html").animate
      scrollTop: 0
    , 500
    false

  scrollTop = undefined
  $('.menu').bind 'touchend', ->
    scrollTop = $(window).scrollTop()
    $('body').addClass 'hidden'
    $('#container').addClass('noscroll').css 'top', -scrollTop + 'px'
    $('#header').addClass 'stop'
    $('#gnavi').fadeIn 300
    return
  $('.nav-close').bind 'touchend', ->
    $('body').removeClass 'hidden'
    $('#container').removeClass 'noscroll'
    # $('#header').addClass 'slideDown'
    $('#gnavi').fadeOut 300
    setTimeout (->
      $('#header').removeClass 'slideUpFirst slideUp stop'
      return
    ), 50
    $(window).scrollTop scrollTop

  hrefDisabled = (e) ->
    e.preventDefault()
    return
  $('.menu').bind 'touchstart', ->
    $('#container a[href],#gnavi a[href]').bind 'click', hrefDisabled
    return
  $('.menu').bind 'touchend', ->
    setTimeout (->
      $('#gnavi a[href]').unbind 'click', hrefDisabled
      return
    ), 500
    return
  $('.nav-close').bind 'touchend', ->
    setTimeout (->
      $('#container a[href]').unbind 'click', hrefDisabled
      return
    ), 1000
    return

  $('.common-nav .tab-item a[href^="#box"]').click ->
    $('.common-nav .tab-box .tab-content').hide()
    $(this.hash).fadeIn(400)
    $('.common-nav .tab-item a').parent().removeClass 'is-active'
    $(this).parent().toggleClass 'is-active'
    false
  #わざと1つ目を表示させておくことができます
  $('.common-nav tab-item a[href^="#box"]:eq(0)').trigger 'click'

  $('.top-nav .tab-item a[href^="#top-box"]').click ->
    $('.top-nav .tab-box .tab-content').hide()
    $(this.hash).fadeIn(400)
    $('.top-nav .tab-item a').parent().removeClass 'is-active'
    $(this).parent().toggleClass 'is-active'
    false
  #わざと1つ目を表示させておくことができます
  $('.top-nav .tab-item a[href^="#top-box"]:eq(0)').trigger 'click'

  $('.qa-list').slick
    autoplaySpeed: 3000
    speed: 1000
    arrows: true
    autoplay: true
    dots: true
    slidesToShow: 1
    slidesToScroll: 1
  return
